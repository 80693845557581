@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #C4C4C4;
}

.black-button {
  @apply bg-primary hover:bg-slate-800 hover:shadow-xl text-white rounded-2xl
  px-7 py-3;
}

.side-nav-item {
  @apply flex items-center bg-primary -ml-6 text-white pl-10 py-3 shadow-xl drop-shadow-xl;
}

.active-side-nav-item {
  @apply flex items-center bg-secondary -ml-6 text-white pl-10 py-3 shadow-xl drop-shadow-xl;
}

.project-card-title-overlay {
  @apply flex flex-col justify-center items-center absolute w-[214px] h-14 left-0 bottom-0 rounded-b-2xl bg-primaryTransparent text-white font-goldman;
}

.project-card-count_overlay {
  @apply flex justify-center items-center left-2 top-2 rounded-full h-14 w-14 absolute bg-primaryTransparent text-white font-goldman text-sm;
}